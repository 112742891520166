/* You can add global styles to this file, and also import other style files */
@import "scss/style";
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import "~@ng-select/ng-select/themes/ant.design.theme.css";

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn .selected-item {
    border: 1px solid #5CA6DB !important;
    margin-right: 4px;
    background: #5CA6DB !important;
    padding: 0 5px;
    color: #fff;
    border-radius: 2px;
    float: left;
    display: flex;
    max-width: 100% !important;
}

.multiselect-dropdown .dropdown-btn {
    display: inline-block;
    border: 0px solid #adadad !important;
    width: 100%;
    height: 100% !important;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 0px !important;
}

.multiselect-item-checkbox input[type="checkbox"] :checked :before {
    -webkit-animation: 0.2s ease-in borderscale;
    animation: 0.2s ease-in borderscale;
    background: #5CA6DB !important;
}

.multiselect-item-checkbox input[type="checkbox"] :checked :after {
    -webkit-animation: 0.2s ease-in borderscale;
    animation: 0.2s ease-in borderscale;
    background: #5CA6DB !important;
}

// global checkbox custome styles
input[type="checkbox"] {
    -webkit-appearance: none !important;
    background-color: #fff !important;
    border: 2px solid #5CA6DB !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05) !important;
    padding: 9px !important;
    border-radius: 3px !important;
    display: inline-block !important;
    position: relative !important;
    top: 7px !important;
}

input[type="checkbox"]:active,
input[type="checkbox"]:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

input[type="checkbox"]:checked {
    background-color: #fff !important;
    border: 2px solid #1a154c !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1) !important;
    color: #99a1a7 !important;
}

input[type="checkbox"]:checked:after {
    content: "\2714" !important;
    font-size: 15px !important;
    position: absolute !important;
    top: -2px !important;
    left: 3px !important;
    color: #1a154c !important;
}

input[type="checkbox"]:disabled {
    border-color: #eee !important;
    color: #ccc !important;
}

.required {
    color: red;
}

.add-close-btn {
    position: absolute;
    top: 15px;
    right: 22px;
    border: 2px solid #5CA6DB !important;
    padding: 2px 7px !important;
    border-radius: 50%;
    color: #5CA6DB !important;
}

.add-close-btn:focus {
    outline: none !important;
}

.desc {
    display: inline-block;
    margin-right: 30px;
    // margin: 0px 15px 0px 8px;
}

.radio-inline input:focus-visible,
.radio-inline input:focus:not(:focus-visible) {
    outline: 2px solid #5CA6DB;
}

// html, body { height: 100%; }
body { font-family: Arial, Helvetica, sans-serif; font-size: 1em; font-weight: 400;line-height: 1.4;}

.spinner-border {
    background: url(../src/assets/images/spinner.gif) no-repeat;
    width: 35px;
    height: 35px;
    background-size: cover;
    border: none !important;
    transition: none !important;
    transform: none !important;
}

// common css for csutom spinner
.custom-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 999;
}

.head-color {
    color: #5CA6DB;
}

.selected-list .c-btn {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 2.2;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 5px 20px !important;
}

.selected-list .c-angle-down,
.selected-list .c-angle-up {
    top: 30% !important;
}

.dropdown-list.tagToBody {
    z-index: 999 !important;
}

// button styles
.blue-btn {
    background: #5CA6DB 0% 0% no-repeat padding-box !important;
    border-radius: 10px !important;
    padding: 10px 25px !important;
    border: none !important;
    letter-spacing: 0px !important;
    color: #FFFFFF !important;
    text-transform: uppercase !important;
    font-size: 14px !important;

    &:hover {
        border: none !important;
        background: #1a154c !important;
    }

    &:active {
        background: #1a154c !important;
    }
}

.dash-actions,
a.btn {
    background: #5CA6DB;
    border-color: #5CA6DB;
    // width: auto !important;
    padding: 10px 25px;
    border-radius: 10px;
    font-size: 14px !important;

    &:hover {
        background: #1a154c !important;
        border-color: #1a154c !important;
    }

    &:active {
        background: #1a154c !important;
        border-color: #1a154c !important;
    }

    text-transform: uppercase;
}

.btn-primary {
    background: #5CA6DB;
    border-color: #5CA6DB;
    // width: auto !important;
    padding: 10px 25px !important;
    border-radius: 10px;
    font-size: 14px !important;
    text-transform: uppercase;

    &:hover {
        background: #1a154c;
        border-color: #1a154c;
    }

    &:active {
        background: #1a154c;
        border-color: #1a154c;
    }
}

button.close {
    font-size: 1.5rem !important;

    &:active {
        border-color: none !important;
    }
}

button {
    border-radius: 10px;
    font-size: 14px;

    &:active {
        border-color: none !important;
    }
}

.btn-secondary {
    padding: 10px 25px !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    text-transform: uppercase;
    background-color: #848da5;
    border-color: #848da5;

    &:hover {
        background-color: #97a0b9;
        border-color: #97a0b9;
    }
}

.primary-color {
    color: #5CA6DB;
}

.form-control:disabled {
    background-color: #e9ecef !important
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

label.radio-inline {
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;

    input {
        position: absolute;
        left: -9999px;

        &:checked+span {

            // background-color: mix(#fff, $primary-color, 84%);
            &:before {
                box-shadow: inset 0 0 0 0.4375em #5CA6DB;
            }
        }
    }

    span {
        display: flex;
        align-items: center;
        // padding: 0.375em 0.75em 0.375em 0.375em;
        border-radius: 99em; // or something higher...
        transition: 0.25s ease;

        &:before {
            display: flex;
            flex-shrink: 0;
            content: "";
            background-color: #fff;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            margin-right: 0.375em;
            transition: 0.25s ease;
            box-shadow: inset 0 0 0 0.125em #5CA6DB;
        }
    }
}

a.disabled,
a.saveContinueDisabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
}

.text-info {
    color: #e7b000 !important;
}

/* width */
// ::-webkit-scrollbar {
//     width: 10px;
//     height: 10px;
//   }

//   /* Track */
//   ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 3px grey; 
//     border-radius: 10px;
//   }

//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: #1a154c; 
//     border-radius: 10px;
//   }

//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: #1a154c; 
//   }
.input-text {
    color: #333;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 40px;
    align-items: center;
    height: 40px;

    &:hover {
        box-shadow: 0 1px 0 #0000000f;
    }
}

textarea {
    color: #333;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;

    &:hover {
        box-shadow: 0 1px 0 #0000000f;
    }
}

// Actions hover bg-colors
.view-action-bg:hover {
    background-color: #8AD2C3 !important;
    color: #fff;
}

.edit-action-bg:hover {
    // background-color: #F5D08E !important;
    background-color: #5CA6DB !important;

    color: #fff;
}

.signoff-action-bg:hover {
    background-color: #6FACE8 !important;
    color: #fff;
}

.delete-action-bg:hover {
    background-color: #FE706E !important;
    color: #fff;
}

.check-status-action-bg:hover {
    background-color: #73BF97 !important;
    color: #fff;
}

.era-action-bg:hover {
    background-color: #8591FD !important;
    color: #fff;
}

.error-action-bg:hover {
    background-color: #FF6464 !important;
    color: #fff;
}

.resubmit-action-bg:hover {
    background-color: #8591FD !important;
    color: #fff;
}

.sig-text-width {
    background: #fff;
    display: inline-block;
    width: 300px;
}

.med-reason-text-width {
    background: #fff;
    display: inline-block;
    width: 150px;
}

.pay-sec-th {
    background: #fff;
    display: inline-block;
    width: 100px;
}

.a-disabled {
    pointer-events: none;
    color: #cbcbcb !important;

    i,
    span {
        color: #cbcbcb !important
    }
}

.appointment-status-clrs {
    .status-clr {
        padding: 4px 8px;
        border-radius: 5px;
    }

    .Confirmed,
    .Completed,
    .Nurse,
    .Scheduled {
        background-color: #DDF4EB;
        color: #46C496
    }

    .Cancelled {
        background-color: #FEF2E9;
        color: #F4785D
    }

    .Triage {
        background-color: #ee9b1930;
        color: #ee841e;
    }


    .Walk-In,
    .Doctor,
    .Consultation {
        background-color: #E0EFFC;
        color: #4C8EC0
    }

    .Show {
        background-color: #e6eaee;
        color: #A7A4A4
    }
}

.d-flex-ver-center {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.sticky-options-select.poc {
    position: sticky;
    // top: 185px;
    background: #fff;
    z-index: 3;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 1px -1px 0px -1px rgba(0, 0, 0, 0.05);
}

.poc-input {
    width: 340px;
    border: none;
    /* height: 50px; */
    padding: 20px;
    border-radius: 10px;
    background-color: transparent;
}

.poc {
    position: relative;
}

.selected-options {
    list-style: none;
    padding-left: 15px;
    margin-bottom: 0;
    padding-top: 15px;

    li {
        display: inline;
    }
}

.poc-all-resources {
    box-sizing: border-box;
    direction: ltr;
    height: 380px;
    position: relative;
    width: 320px;
    will-change: transform;
    background: var(--ds-surface-overlay, #FFFFFF);
    border-radius: 3px;
    box-shadow: var(--ds-shadow-overlay, 0 0 1px rgba(9, 30, 66, 0.31), 0 4px 8px -2px rgba(9, 30, 66, 0.25));
    padding: 4px 0px;
    max-height: 380px;
    overflow-y: auto;
    position: absolute;
    z-index: 99;
    top: 70%;

    ul {
        padding: 0px;

        li {
            cursor: pointer;
            list-style: none;
            padding: 10px;

            &:hover {
                color: #fff;
                background-color: #5CA6DB;
            }
        }
    }
}

.select-all-list-options {
    float: right;
    text-decoration: underline !important;
    cursor: pointer;
    color: #000;
    font-weight: 600;
    line-height: 27px;

    &:hover {
        color: #5CA6DB !important;
    }
}

.dataTable>thead>tr>th[class*="sort"]:before,
.dataTable>thead>tr>th[class*="sort"]:after {
    content: "" !important;
}
.followup-queue-total {
    table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > td.sorting:before, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:before, table.dataTable thead > tr > td.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:before{
        content: "▲" !important;
        right: auto;
        left: 0px;
    }
    table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting:after, table.dataTable thead > tr > td.sorting_asc:after, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc_disabled:after, table.dataTable thead > tr > td.sorting_desc_disabled:after{
        content: "▼" !important;
        right: auto;
        left: 0px;
    }
    table.dataTable thead > tr > th:last-child{
       &:before, &:after{
        content:"" !important;
       }
    }
}
.custom-file-label {
    height: calc(1.5em + 3.25rem + 4px);
    position: absolute;
    top: 31px;
}

.custom-file-container p {
    margin-top: 37px;
    padding: 0 20px;
    padding: 0 20px;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px grey;
    // border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1a154c;
    // border-radius: 8px;
}

.modal-dialog-centered .modal-content {
    border: 3px solid #5CA6DB;
}

.dotted {
    border-bottom: 2px dashed #000;
    text-align: center;
    height: 10px;
    margin-bottom: 20px;
}

.dotted h5 {
    background: #fff;
    padding: 0 5px;
    display: inline-flex;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #ffffff transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;

    &:hover {
        border-color: #ffffff transparent transparent;
    }
}

.ng-select .ng-arrow-wrapper {
    width: 25px;
    padding-right: 0px;
    background-color: #5CA6DB;
    height: 40px;
    padding-top: 8px;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
    border-top-color: #fff;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {

    border-color: transparent transparent #fff;

}

.test-details {

    .ng-select .ng-arrow-wrapper .ng-arrow {
        border-color: #999 transparent transparent;
        border-style: solid;
        border-width: 5px 5px 2.5px;

        &:hover {
            border-color: #999 transparent transparent;
        }
    }

    .ng-select .ng-arrow-wrapper {
        width: 25px;
        padding-right: 0px;
        height: 40px;
        padding-top: 8px;
        background-color: #fff;
    }

    .ng-select .ng-arrow-wrapper:hover .ng-arrow {
        border-top-color: #999;
    }

    .ng-select.ng-select-opened>.ng-select-container .ng-arrow {

        border-color: transparent transparent #999;

    }

}