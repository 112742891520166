/**  =====================
      Custom css start
==========================  **/
body {
    font-family: $theme-font-family;
    font-size: $theme-font-size;
    color: $theme-font-color;
    font-weight: 400;
    background: $theme-background;
    position: relative;
}

* {
    &:focus {
        outline: none;
    }
}

@font-face {
    font-family: 'Maison-Neue-Book';
    src: url(../../assets/fonts/maison/Maison-Neue-Book.ttf);
}

@font-face {
    font-family: 'Maison-Neue-Bold';
    src: url(../../assets/fonts/maison/Maison-Neue-Bold.ttf);
}

a {
    &:hover {
        outline: none;
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5 {
    color: $theme-heading-color;
    font-weight: 600;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 16px;
    color: $theme-heading-color;
    font-weight: 400;
}

p {
    font-size: 13px;
}

b,
strong {
    font-weight: 600;
}

.img-radius {
    border-radius: 50%;
}

.page-header {
    background: transparent;
    margin-bottom: 30px;

    h5 {
        margin-bottom: 15px;
        color: $theme-heading-color;
    }

    .page-header-title+.breadcrumb {
        background: transparent;
        padding: 0;
        margin-bottom: 0;

        >.breadcrumb-item {
            line-height: 1;

            &:before {
                color: transparentize($theme-font-color, 0.4);
            }

            a {
                color: $theme-font-color;

                font: {
                    weight: 400;
                }

                &:hover {
                    color: #5ca6db;
                }
            }

            &:last-child a {
                color: #000;
                font-weight: 600;
            }

            &:hover {
                a {
                    color: #5BA7DC;
                }
            }
        }
    }

    &:not(.breadcumb-sticky) {
        .page-header-title+.breadcrumb {
            >.breadcrumb-item {
                a {
                    font: {
                        size: 13px;
                    }
                }
            }
        }
    }

    &.breadcumb-sticky {
        // padding: 15px 25px;
        // margin-top: -25px;
        // margin-left: -25px;
        // margin-right: -25px;
        // box-shadow: 0 0 3px 0 rgba(54, 80, 138, 0.3);
        overflow: hidden;
        position: relative;


        .page-header-title {
            display: inline-block;

            h5 {
                margin-bottom: 0;

            }
        }

        .breadcrumb {
            display: inline-flex;
            float: right;
            margin-top: 4px;

            @media only screen and (max-width: 767px) {
                display: flex;
                float: none;
            }
        }
    }
}

.pcoded-main-container {
    .page-header {
        +.row {
            // margin-top: -35px;
        }
    }
}

/* ==========  card css start  =========== */

.anim-rotate {
    animation: anim-rotate 1s linear infinite;
}

@keyframes anim-rotate {
    100% {
        transform: rotate(360deg);
    }
}

.anim-close-card {
    animation: anim-close-card 1.4s linear;
}

@keyframes anim-close-card {
    100% {
        opacity: 0.3;
        transform: scale3d(.3, .3, .3);
    }
}

/* ==========  scrollbar End  =========== */

.scroll-y {
    z-index: 1027;
}

/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */

.dropdown-menu {
    padding: 10px 0;
    // box-shadow: 0 0 4px 0 rgba(43, 43, 43, 0.25);
    // border: none;
    font-size: $theme-font-size;

    >li,
    >.dropdown-item {
        padding-left: 18px;
        padding-right: 18px;
        margin: 0;
        width: auto;
        border-radius: 0;

        >a {
            padding: 5px;
            color: $theme-font-color;

            i {
                font-size: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
            }
        }

        &.active,
        &:active,
        &:focus,
        &:hover {
            background: $primary-color;
            color: #fff;
            box-shadow: 0 0 4px 0 rgba(43, 43, 43, 0.25);

            >a {
                background: transparent;
                color: #fff;
            }
        }
    }

    >a {

        &.active,
        &:active,
        &:focus,
        &:hover {
            background: $primary-color;
            color: #fff;
        }
    }
}

.drp-icon {
    &:after {
        display: none;
    }
}

/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
    .container {
        max-width: 1540px;
    }
}

@media (max-width: 992px) {
    .container {
        max-width: 100%;
    }
}

/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/

.bd-example-row {
    background: $theme-background;
    padding: 15px;
    margin-bottom: 15px;

    .row {

        >[class^="col-"],
        >.col {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            background-color: rgba(86, 61, 124, .15);
            border: 1px solid rgba(86, 61, 124, .2);
        }
    }

    .row+.row {
        margin-top: 1rem;
    }

    .flex-items-bottom,
    .flex-items-middle,
    .flex-items-top {
        min-height: 6rem;
        background-color: rgba(255, 0, 0, .1);
    }
}

.bd-example-row-flex-cols .row {
    min-height: 10rem;
    background-color: rgba(255, 0, 0, .1);
}

/* Docs examples */

.bd-example {
    position: relative;
    padding: 1rem;
    margin: 1rem;
    border: solid #ddd;
    border-width: 0.2rem 0 0;

    @media only screen and (max-height: 575px) {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 0.2rem;
    }
}

/* Example modals */

.modal {
    z-index: 1072;

    .popover,
    .tooltip {
        z-index: 1073;
    }
}

.modal-backdrop {
    z-index: 1071;
}

.bd-example-modal {
    background-color: #fafafa;

    .modal {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        left: auto;
        margin-right: auto;
        margin-left: auto;
    }
}

/* Example tabbable tabs */

.bd-example-tabs .nav-tabs {
    margin-bottom: 1rem;
}

/* Code snippets */

.highlight {
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #f3f2fb;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    @media only screen and (max-height: 575px) {
        padding: 1.5rem;
    }
}

.bd-content .highlight {
    margin-right: 15px;
    margin-left: 15px;

    @media only screen and (max-height: 575px) {
        margin-right: 0;
        margin-left: 0;
    }
}

.highlight {
    pre {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: transparent;
        border: 0;
    }

    pre code {
        font-size: inherit;
        color: #333;
    }
}

:not(pre)>code[class*="language-"],
pre[class*="language-"] {
    background: transparent;
}

/* =======================================================================
 ===============     Ui kit copy model style      ====================== */
/* Code examples */

.datta-example {
    position: relative;

    .datta-example-btns {
        position: absolute;
        right: 30px;
        top: -10px;
        opacity: 0;
        transform: rotateX(-90deg);
        transition: all 0.3s ease-in-out;

        .datta-example-btn {
            display: inline-block;
            line-height: 1;
            font-weight: 600;
            background: $primary-color;
            color: #fff;
            padding: 0.1875rem 0.3125rem;
            border-radius: 2px;
            white-space: nowrap;
            font-size: 11px;
            margin: 0 4px;

            &.copy::before {
                content: "COPY";
            }

            &.copied {
                background: $success-color !important;
                color: #fff !important;

                &::before {
                    content: "COPIED!";
                }
            }
        }
    }

    &:hover .datta-example-btns {
        top: -21px;
        transform: rotateX(0deg);
        opacity: 1;
    }
}

/* Modal */

.datta-example-modal {
    position: fixed;
    z-index: 1099;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #282c34;
    transform-origin: 50% 0;
    transform: scale(0.0);
    transition: all 0.3s ease-in-out;
}

.datta-example-modal-opened {
    overflow: hidden !important;

    .datta-example-modal {
        transform: scale(1);
    }
}

.datta-example-modal-content {
    max-width: 100vw;
    margin: auto;
    padding: 50px;
    height: 100vh;
    overflow: auto;

    >pre {
        overflow: hidden;
        width: fit-content;

        >code {
            padding: 0;
            background: none;
            font-size: 16px;
        }
    }
}

.md-datta-example-modal-copy {
    display: block;
    position: fixed;
    top: 90px;
    right: 30px;
    margin-right: 8px;
    font-size: 1.5rem;
    border-radius: 50%;
    padding: 10px 15px;
    background: $primary-color;
    color: #fff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    white-space: nowrap;

    &:hover {
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
        color: #fff;
    }

    &.copied::before {
        content: "Copied to Clipboard Successfully ! . . .";
        position: absolute;
        display: block;
        right: 100%;
        margin-right: 10px;
        font-size: 14px;
        background: $success-color;
        line-height: 24px;
        height: 24px;
        border-radius: 3px;
        padding: 0 6px;
        top: 50%;
        margin-top: -12px;
    }
}

.datta-example-modal-close {
    display: block;
    position: fixed;
    top: 10px;
    right: 52px;
    color: #fff;
    opacity: 0.2;
    font-size: 3rem;
    font-weight: 100;
    transition: all 0.3s ease-in-out;
    z-index: 1;

    &:hover {
        color: #fff;
        opacity: 0.9;
    }
}

/* Code */
.cui-bottom-spacer {
    height: 12rem;
}

/* editor style for model */
.hljs {
    padding: 0.5em;
    color: #abb2bf;
    background: #282c34;
}

.hljs-comment,
.hljs-quote {
    color: #5c6370;
    font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
    color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
    color: #e06c75;
}

.hljs-literal {
    color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
    color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
    color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
    color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
    color: #61aeee;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}

.hljs-link {
    text-decoration: underline;
}

/* ================================    md animated mode start   ===================== */

.md-show.md-effect-12~.md-overlay {
    background: $primary-color;
}

/* ================================    md animated mode End   ===================== */
/* ================================    range slider Start  ===================== */

.tooltip {
    &.in {
        opacity: 0.9;
    }

    .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    &.top {
        padding: 5px 0;
        margin-top: -3px;

        .tooltip-arrow {
            bottom: 0;
            left: 50%;
            margin-left: -5px;
            border-width: 5px 5px 0;
            border-top-color: #000;
        }
    }
}

/* ================================    range slider End  ===================== */
/* ================================    owl-carousel slider Start  ===================== */

.owl-carousel {
    .owl-nav {
        button {

            &.owl-next,
            &.owl-prev {
                padding: 3px 9px;
            }
        }
    }

    button.owl-dot {}
}

/* ================================    owl-carousel slider End  ===================== */
/* ================================    Bootstrap tags input Start  ===================== */

.bootstrap-tagsinput {
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 2px;
    background: $form-bg;

    .tag {
        padding: 5px 12px;
        border-radius: 2px;
        line-height: 37px;
        margin-top: 5px;
        margin-right: 5px;

        [data-role="remove"] {
            margin-right: -5px;

            &:after {
                content: "\e8f6";
                padding: 0 2px;
                font-family: 'feather' !important;
            }
        }
    }
}

/* ================================    Bootstrap tags input End  ===================== */
/* ================================    Multi-Select Start  ===================== */

.ms-container {
    width: 100%;
}

/* ================================    Multi-Select End  ===================== */
/* ================================    Blockquote Start  ===================== */

.blockquote {
    border-left: 0.25rem solid $theme-border;
    padding: 0.5rem 1rem;

    &.text-right {
        border-left: none;
        border-right: 0.25rem solid $theme-border;
    }
}

/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */

.card .animated {
    animation-duration: 1s;
    border: 5px solid #fff;
    margin-bottom: 30px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
}

/* ================================    animation End  ===================== */
/* ================================    browser  warning  Start  ===================== */

.ie-warning {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #000000;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
    padding: 50px 0;

    p {
        font-size: 17px;
    }

    h1 {
        color: #fff;
    }

    .iew-container {
        min-width: 1024px;
        width: 100%;
        height: 200px;
        background: #fff;
        margin: 50px 0;
    }

    .iew-download {
        list-style: none;
        padding: 30px 0;
        margin: 0 auto;
        width: 720px;

        >li {
            float: left;
            vertical-align: top;

            >a {
                display: block;
                color: #000;
                width: 140px;
                font-size: 15px;
                padding: 15px 0;

                >div {
                    margin-top: 10px;
                }

                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
}

/* ================================    browser  warning  End  ===================== */
div.code-toolbar {

    >.toolbar a,
    >.toolbar span,
    >.toolbar button {
        padding: 3px 0.9em !important;
        background: #04a9f5 !important;
        color: #fff !important;
        box-shadow: none !important;
    }
}

pre[class*=language-]:after,
pre[class*=language-]:before {
    display: none;
}

/* table css */

.table-borderless tbody tr td,
.table-borderless tbody tr th {
    border: 0;
}

@media only screen and (max-width: 400px) {

    .lay-customizer .theme-color>a[data-value="reset"],
    .theme-color>a[data-value="reset"] {
        margin-top: 20px;
    }
}

.main-section .cal-week-view .cal-header.cal-today {
    background-color: #c5e7ff;
    color: #373a3c;

    &:span {
        color: #373a3c;
    }
}

.main-section .cal-month-view .cal-day-cell.cal-today {
    background-color: #c5e7ff;
    color: #373a3c;

}

aw-wizard-navigation-bar.vertical.small ul.steps-3.steps-indicator li.done .step-indicator {
    background-color: #5CA6DB;
}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.navigable.done a:hover .step-indicator {
    background-color: #1a154c;
}

.link-text {
    color: #2B3278;
    font-weight: 500;

    &:hover {
        color: #2B3278;
    }
}

.custom-nav-tabs {

    // .new-designed-navbar-tabs{
    //     border-bottom: none !important;
    //     background: white;
    //     width: fit-content;
    //     margin: 0 auto;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    //     border-radius: 50px;
    //     padding: 10px 15px;
    //     .nav-link{
    //         padding: 5px 25px !important;
    //         font-weight: 600;
    //         font-size: 14px;
    //         line-height: 24px;
    //         color: #8494B5;
    //         background-image: linear-gradient(to top, #5CA6DB 3px, rgba(255, 255, 255, 0) 2px);
    //         margin-right: 0px;
    //         border-bottom: none;
    //         background-image: none;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         span.material-symbols-outlined, i{
    //             font-size: 18px;
    //         }
    //     }
    //     .nav-link.active{
    //         font-weight: bold;
    //         font-size: 14px;
    //         line-height: 24px;
    //         letter-spacing: 0px;
    //         color: #fff;
    //         background-color: #5CA6DB !important;
    //         border-bottom: none;
    //         background-image: none;
    //         border-radius: 50px;
    //     }
    // }
    .btn.btn-status-success,
    .btn.btn-status-rescheule,
    .btn.btn-status-cancel {
        background: #DDF4EB;
        color: #46C496;
        font-size: 13px;
        line-height: 14px;
        font-weight: 600;
        border-radius: 5px;
    }

    .btn.btn-status-rescheule {
        background-color: #E0EFFC;
        color: #4C8EC0;
    }

    .btn.btn-status-cancel {
        background-color: #FEF2E9;
        color: #F4785D;
    }

    .btn.btn-status-triage {
        background-color: #ee9b1930;
        color: #ee841e;
    }

    .icon-queue-events {
        background: #f2f1f2 0% 0% no-repeat padding-box;
        border-radius: 10px;
        border: none;
        padding: 7px 10px;
        margin-right: 10px;
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);

        &:hover:not(:disabled) {
            background: #5CA6DB;
            color: #fff;

            i,
            span {
                color: #fff;
            }
        }

        &:focus {
            outline: none;
            border: none;
        }

        &:active {
            outline: none;
            border: none;
        }

        i,
        span {
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #8C8E8F;
        }
    }

    .send-icon {
        background: #5CA6DB;
        border: solid;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: transparent;

        i {
            color: #fff;

            &:before {
                font-family: 'Font Awesome 5 Free';
                position: relative;
                left: 4px;
                top: 2px;
                font-size: 12px;

            }
        }
    }
}


.common-modal {
    .modal-dialog {
        max-width: 1200px;

        .modal-content .modal-header {
            display: block;

            .modal-title {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-bottom: 10px;
            }
        }

        .modal-body {
            position: relative;

            .table.common-table td {
                padding: 0.5rem;
                font-size: 14px;
                vertical-align: middle;

                @media (min-width:1440px) {
                    word-break: break-word;
                    max-width: 240px;
                    white-space: normal;
                }
            }
        }
    }
}

#verifyAvailableReportModal {
    .modal-dialog {
        max-width: 1200px;

        .verify-status {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            .not-verify {
                color: #956C24;
                font-size: 13px;
                width: 78%;
                font-weight: bold;
            }

            .btn {
                font-size: 13px !important;
                padding: 4px 10px !important;
                float: right;
                text-transform: capitalize;
                font-weight: normal;
                line-height: 14px;
                border-radius: 4px;
            }
        }

        .verify-name {
            margin-bottom: 20px;

            h4 {
                font-size: 20px;
                line-height: 27px;
                color: #000;
                margin-bottom: 0;
            }

            p {
                small {
                    color: #808080;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        .verify-txtarea {
            .form-control {
                border-radius: 6px;
            }
        }

        .verify-stick-menu {
            background-color: #1a154c;
            font-size: 12px;
            display: flex;
            align-items: center;
            height: 54px;
            width: 96%;
            position: absolute;
            bottom: 0;
            padding: 10px 14px;
            border-radius: 3px;
            right: 10px;

            .verify-prev,
            .verify-next {
                color: #fff;
                width: 12%;
                letter-spacing: 0.5px;
                cursor: pointer;
            }

            .verify-next {
                text-align: right;
            }

            .verify-prev {
                width: 17%;
            }

            .disabled {
                color: #aaa;
                cursor: default;
            }

            .stick-menu-list {
                padding: 0;
                margin: 0;
                width: 72%;
                text-align: center;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 15px;

                    a {
                        padding: 10px 14px !important;
                        color: #5CA6DB;
                        border: solid 1px #5CA6DB;
                        color: #5CA6DB;
                        border-radius: 4px;
                        width: 100px;
                        display: inline-block;
                        position: relative;
                        cursor: pointer;

                        &.disabled {
                            cursor: none !important;
                            color: #fff;
                            border-color: #1a154c;
                        }

                        &:hover {
                            background-color: #5CA6DB;
                            color: #fff;
                            border-color: #5CA6DB;
                        }
                    }

                    &.menu-select {
                        a {
                            background-color: #599662;
                            color: #fff;
                            padding-left: 30px !important;
                            border-color: #599662;

                            i {
                                margin-right: 8px;
                                position: absolute;
                                left: 12px;
                                display: inline-block;
                                height: 0;
                                top: 5px;


                            }

                            &.check-hide {
                                padding-left: 10px !important;

                                i {
                                    display: none;
                                }
                            }

                            &:hover {
                                border-color: #599662 !important;
                            }

                        }

                        &:hover {
                            border-color: #599662 !important;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .consen-btn-group {
            float: right;

            .consented-btn {
                border-radius: 16px;
                background-color: #A4D65E;
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #000;
                border: none;
                padding: 8px 10px 8px 42px;
                font-weight: 600;
                float: right;
                position: relative;
                width: 138px;

                i,
                span {
                    border-radius: 50%;
                    background-color: #599662;
                    color: #fff;
                    font-size: 12px;
                    position: absolute;
                    left: 0;
                    border: none;
                    padding: 10px;
                }

                &.non {
                    background-color: #F68D8D;

                    i,
                    span {
                        background-color: #E90909;
                        color: #fff;
                        padding: 10px 12px;

                    }
                }
            }
        }
    }
}

#viewDownloadDocumentModal .modal-body {
    padding-bottom: 100px !important;
}

.verify-stick-menu {
    background-color: #1a154c;
    font-size: 12px;
    display: flex;
    align-items: center;
    height: 54px;
    width: 48.5%;
    position: absolute;
    bottom: 25px;
    padding: 10px;
    border-radius: 3px;
    right: 24%;

    .verify-prev,
    .verify-next {
        color: #fff;
        width: 14%;
        letter-spacing: 0.5px;
    }

    .verify-next {
        text-align: right;
    }

    .stick-menu-list {
        padding: 0;
        margin: 0;
        width: 72%;
        text-align: center;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 15px;

            a {
                padding: 4px 14px !important;
                color: #fff;
            }

            &:hover,
            &:hover a {
                color: #5CA6DB;
                border-color: #5CA6DB !important;
            }

        }
    }
}

.pcoded-navbar.navbar-collapsed+.pcoded-header {
    .logo {
        background: url(../../../src/assets/images/auth/Provider_logo_nav.png) no-repeat;
        width: 41px;
        height: 41px;
        background-size: contain;
        top: 0;
        left: -10px;
    }
}

.pcoded-navbar+.pcoded-header {
    .logo {
        background: url(../../../src/assets/images/auth/logo.png) no-repeat;
        width: 206px;
        height: 70px;
        background-size: contain;
        filter: brightness(0) invert(1);
        position: relative;
        top: 15px;
        left: -21px;
    }
}

#viewInsurance .basic.modal {
    z-index: 9999 !important;
    // .modal-content{
    //     padding: 0;
    // }
}

#insuraceCardModalId .basic.modal {
    z-index: 99991 !important;
}

#insuraceCardModalId .modal-body {
    height: 450px;
}

.custom-modal#notifyTextModal {
    .modal-dialog {
        max-width: 460px !important;
    }

    .modal-title {
        // line-height: 50px;
    }
}
.custom-modal#claimSubmitNotify {
    .modal-dialog {
        max-width: 460px !important;
    }

    .modal-title {
        // line-height: 50px;
    }
}

#addNewInsurance {
    // .basic.modal{
    //     // z-index: 3 !important;
    // }
    // .modal-content{
    //     padding: 0;
    // }
    // .modal-dialog{
    //     margin: 10px auto;
    // }
}

#viewInsurance .basic.modal {
    // height: fit-content;
}

.appointment-q {
    #dash-table {
        position: relative;
        top: -62px;
    }

    .dataTables_wrapper {
        .dataTables_filter {
            position: relative;
            right: 60px;
        }

        .dataTables_length {
            position: relative;
            top: 0px;
            left: 370px;
        }
    }


}

.panelEM {
    .checkbox.checkbox-primary.d-inline {
        display: inline-flex !important;
    }

    input[type=checkbox] {
        height: 22px;
    }
}

.card.card-load .card-loader {
    z-index: 1;
}

.vaccine-details {
    .form-group.col-1 {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.desc1 {
    margin-right: 25px;
}

.procedure-administartion {
    .ng-select.ng-select-single .ng-select-container {
        font-size: 14px;
    }
}




#profilepicUploadModal {
    .modal-body {
        padding: 0px;
    }
}

#profileImageModal {

    .modal-body {
        padding: 0px;
    }

}

#fullView {
    .modal-body {
        padding: 0px;
    }
}

#pdfDocumentModal {
    .modal-footer {
        display: none;
    }

    .modal-content {
        padding-bottom: 0px;
    }
}

// .custome-navbar.pcoded-header,.pcoded-navbar{
//     z-index: 1 !important;
// }
.ng-select.ng-select-opened .ng-select-container,
.ng-dropdown-panel {
    z-index: 4 !important;
}


/*Calender Refactor css*/
#calenderEventModal .modal-dialog-centered .modal-content .modal-footer {
    padding: 0;
}

.cal-week-view .cal-day-headers .cal-header,
.cal-week-view .cal-time {
    color: #333 !important;
    font-size: 12px !important;
}

.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: solid 1px #F5F6F7 !important;
}

.cal-week-view .cal-hour-odd {
    background-color: #fff !important;
}

.cal-week-view .cal-ends-within-day .cal-event {
    background: #EFF5FB;
    border: solid 2px #5ca6db;
    padding: 0 12px;
    position: absolute;
    // color: #4D5E80;
    font-weight: 600;
    width: auto;
    min-height: 36px;
    font-size: 7px;
    top: unset;
    display: flex;
    align-items: center;
    text-overflow: revert;
    white-space: normal;
    // overflow: auto;
    line-height: 12px;
    max-width: 130px;
}

.cal-week-view .cal-time-events .cal-event-container {
    // top:auto !important;
}

.cal-week-view mwl-calendar-week-view-hour-segment,
.cal-week-view .cal-hour-segment {
    display: block;
}

.cal-week-view .cal-hour-segment {
    display: flex;
    align-items: center;
}

.cal-event-title {
    display: block;
}

#calenderEventModal {
    .modal-content {
        background-color: #F7FAFD;
    }
}

.cal-header .cal-cell {
    font-size: 10px;
}

.cal-cell-top {
    min-height: 30px !important;
    color: #000 !important;
}

.cal-day-cell {
    min-height: 30px !important;
    font-size: 10px;
    width: 40px;
}

.cal-day-cell.cal-today .cal-day-number {
    font-size: 12px !important;
}

// .cal-day-cell.cal-today{
//     background-color: #5CA6DB !important;
//     color: #fff;
// }
.cal-month-view .cal-day-number {
    opacity: 1;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    color: #B6C0D9;
    opacity: 1;
}

.cal-event-container.cal-starts-within-day.cal-ends-within-day .cal-event {
    // background-color: rgba(255, 0, 0, .1) !important;
    display: block;
    padding: 2px 0 0 2px;
    width: 100%;
    max-width: 100%;
    line-height: 8px;
    font-weight: normal;
    box-shadow: 0 0 3px rgba(0, 0, 0, .9);

    .cal-event-title {
        // color: #4D5E80;
        float: left;
    }
}

.cal-event-actions {
    a {
        color: #ff0000;
    }

    .cal-event-action {
        display: inline-block;
        float: right;
    }

    .calendar-edit-action,
    .calendar-delete-action {
        float: none;

        i {
            font-size: 15px;
            color: #fff;
        }
    }
}

mwl-calendar-open-day-events {
    .cal-cell-top {
        min-height: 30px !important;
        color: #000 !important;
    }
}

.cal-month-view .cal-day-cell.cal-today {
    background-color: #c5e7ff;
}

.main-section.cal-expand {
    .cal-month-view .cal-cell-top {
        min-height: 78px !important;
    }
}

// .cal-month-view .cal-event{
//     background-color: #1a154c !important;
// }

#quickReferralModal {
    .modal-dialog {
        max-width: 1150px;
    }

    .modal-footer {
        display: none;
    }
}

#editclientstatus {

    .modal-dialog {
        max-width: 1300px;
    }

    .modal-body {
        max-height: 500px;
        overflow-y: auto;
    }

}

#claimPostChargesModal {
    .modal-body {
        max-height: 550px;
        overflow: auto;
    }
}

.claims-table {

    div.dataTables_scrollHeadInner table.table-bordered tr th:first-child {
        border-left: none !important;
    }



    .dataTables_wrapper.no-footer div.dataTables_scrollBody>table {
        border-bottom: none !important;
    }

    .dataTables_wrapper.no-footer .dataTables_scrollBody {
        border-bottom: none !important;
    }



    div.dataTables_scrollBody {
        border-left: none !important;
    }

    div.dataTables_scrollFootInner table.table-bordered tr th:first-child,
    div.dataTables_scrollHeadInner table.table-bordered tr th:first-child {
        border-left: none !important;
    }

    .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td {
        vertical-align: top;
    }

}

#viewCommonAppointmentDetails {
    .modal-body {
        padding: 0px;
    }

    .card {
        margin-bottom: 15px;
    }

    .card-body {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .modal-content {
        padding-bottom: 0px;
    }
}

.table {
    color: #333333;
}

#updateExamStatus,
#updateRoomNumber {
    .modal-dialog {
        max-width: 390px;
    }
}

.sticky {
    cursor: pointer;
    position: sticky;
    z-index: 1;
    left: 0;
}

.stickyfixed {
    position: sticky;
    z-index: 1;
    left: 140
}

.stickyRight {
    cursor: pointer;
    position: sticky;
    z-index: 1;
    right: 0;
}

.sticky1 {
    z-index: 99;
}

.stickyHeader {
    position: sticky;
    top: 0;
    z-index: 3;
}

.nrf1 {
    letter-spacing: 0px;
    color: #000;
    text-transform: uppercase;
    opacity: 1;
    background: #fff !important;
}

.text-left {
    .test-details {
        text-align: justify;
    }
}

#viewEobModal {
    .modal-dialog {
        max-width: 1250px;
    }

    .modal-dialog-centered .modal-content {
        padding: 10px !important;
    }
}

#self-pay {

    .dataTables_scrollHeadInner {
        width: auto !important;
    }

}
.claim-tooltip .show.tooltip{
    padding-left: 10px;
    padding-right: 10px;
    width: 130px;
    font-size: 12px !important;
}

.history-of-present-illness  .ng-select.ng-select-opened .ng-select-container, .history-of-present-illness  .ng-dropdown-panel{
    z-index: 3 !important;
}
.history-of-present-illness .ngx-slider .ngx-slider-pointer{
    z-index: 2;
}

#addNewDevice{
    .modal-dialog{
        max-width: 600px;
    }
}
#claimPostChargesModal{
    .info-details2 > li:nth-child(2){
        .claims-appnt-itemized{
            padding-top: 0;
        }
    }
}
.enrollment-sec .dataTable > thead > tr > th[class*=sort]:before,
.enrollment-sec .dataTable > thead > tr > th[class*=sort]:after
{
  display: none !important;
  content:"" !important;
}
.custom-modal-UI{
    .modal-dialog {
        max-width:1200px !important;
    }
     .modal-content .modal-header{
        padding-bottom: 0 !important;
    }
    .modal-body {
        height: 650px;
        overflow-y: scroll;
    }
}