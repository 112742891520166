perfect-scrollbar, .elite-scroll {
  .ps__rail-y {
    &:hover > .ps__thumb-y, &:focus > .ps__thumb-y, &.ps--clicking .ps__thumb-y {
      background-color: #1B154C;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: #1B154C;
    border-radius: 6px;
    width: 5px;
    right: 0; 
  }
}

perfect-scrollbar > .ps, perfect-scrollbar.elite-scroll > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover, &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y, &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}
